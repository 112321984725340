import { isSurveyWizardContext, WizardContexts, WizardTypes } from 'app/shared/models';
import { Actions as FollowupWizardActions } from 'app/state/actions/followup-wizard.actions';
import { Actions as ProductWizardActions, Type as ProductWizardActionType } from 'app/state/actions/product-wizard.actions';
import { Actions as SurveyWizardActions } from 'app/state/actions/survey-wizard.actions';

export function handleProductWizardActions(
  state: WizardContexts,
  action: SurveyWizardActions | FollowupWizardActions | ProductWizardActions,
  wizardType: WizardTypes,
  initialState: WizardContexts) {
  switch (action.type) {

    case ProductWizardActionType.CREATE_FROM_SURVEY_SUCCESS: {
      if (action.payload?.wizardType === wizardType) {
        return action.payload != null ? { ...action.payload, saving: false } : { ...initialState };
      } else {
        return state;
      }
    }

    case ProductWizardActionType.SET_WIZARD_STATE: {
      if (action.payload?.wizardType === wizardType) {
        return action.payload != null ? { ...action.payload, saving: false } : { ...initialState };
      } else {
        return state;
      }
    }

    case ProductWizardActionType.SELECT_PRODUCT_DEFINITION: {
      if (action.wizardType === wizardType) {
        if (isSurveyWizardContext(state)) {
          return ({ ...initialState });
        }
      } else {
        return state;
      }
      break;
    }

    case ProductWizardActionType.SELECT_PRODUCT_DEFINITION_SUCCESS: {
      if (action.wizardType === wizardType) {
        return { ...state, productDefinition: { ...action.payload, definitionObject: JSON.parse(action.payload.definition) } };
      } else {
        return state;
      }
    }

    case ProductWizardActionType.SAVE_DRAFT: {
      return { ...state, pristine: false, saving: true };
    }

    case ProductWizardActionType.CREATE_DRAFT: {
      return { ...state, saving: true };
    }

    case ProductWizardActionType.DRAFT_SAVED: {
      if (action.wizardType === wizardType) {
        return { ...state, productId: action.payload.productId, currentVersion: action.payload.currentVersion, saving: false };
      } else {
        return { ...state, saving: false };
      }
    }

    case ProductWizardActionType.SET_PAGE: {
      if (action.wizardType === wizardType) {
        return { ...state, activeStep: action.payload };
      } else {
        return state;
      }
    }
    case ProductWizardActionType.SET_CHANGED_SUCCESS: {
      return { ...state, unsaved: action.payload.changed }
    }

    default: {
      return null;
    }
  }
}
